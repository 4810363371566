@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.test-container{
    margin: 3% 5%;
    display: flex;
    font-size: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 75px; */
}
.icon1-container{
    width: 10%;
}
.icon1{
    display: flex;
    text-align: center;
    width: 100%;
    /* margin: .2rem;    */
}
.icons-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.heading1{
    font-family: 'Roboto';
    color: #1F284F;
    font-weight: bold;
    font-size: 1.5rem;
}
.para1{
    font-family: 'Roboto';
    font-size: 1rem;
    color:  #1F284F;
    text-align: center;
    width: 85%;
    padding-top: 10px;

}
@media(max-width:768px){
    .heading1{
        font-family: 'Roboto';
        color: #1F284F;
        font-weight: bold;
        font-size: .8rem;
    }
    .para1{
        font-family: 'Roboto';
        font-size: .5rem;
        color:  #1F284F;
        text-align: center;
        width: 85%;
        padding-top: 10px;
    
    }
}