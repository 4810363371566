/* Main container styling */
.container-for-selection-letter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    margin: 0 auto;
    max-width: 90%;
}

.heading-for-selection-letter {
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.selection-letter-image {
    width: 24px;
    height: 24px;
    margin-right: 15px;
}

.table-container {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
    margin-bottom: 50px;
}

.table {
    width: 100%;
    min-width: 600px;
    font-size: 0.9rem;
}

th, td {
    padding: 8px;
    text-align: center;
    font-family: 'Inter', sans-serif;
}

/* Rotation for Mobile Screens */
@media only screen and (max-width: 768px) {
    .rotate {
        transform: rotate(90deg);
        transform-origin: center center;
        display: flex;
        flex-direction: column; /* Stacks items vertically */
        align-items: center;
        width: 100vh;
        height: 100vw;
    }

    .selection-letter-image {
        margin-bottom: 15px; /* Adds spacing below image */
    }

    .table-container {
        margin: 0 auto;
        width: 100%;
    }

    .table {
        font-size: 0.8rem;
    }

    th, td {
        font-size: 0.7rem;
    }
}
