.modal-body-leterhead {
    position: relative; 
    height: 100vh;
  }
  #selectionlettermodal{
    width: 60%;
    border: 1px solid gray;
  }
  .modal-body-leterhead::before {
    content: "";
    background-image: url("../../assets/Images/bgleterheadconfrm.png");
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
   
  }


.footer{
   background-image: url('../../assets/Images/Rectangle1.png');
   background-size: cover;
   height: 45px;
   position: absolute;
}
.bg-2{
    background-image: url('../../assets/Images/Rectangle2.png');
    background-size: cover;
}

.footer-container{
  display: flex;
}

.background-image{
  position: absolute;
  /* margin-left: 110px;     */
  opacity: .2;

}

.footerBg{
  background:linear-gradient(135deg, white 70%, #263672 30%) ;
  height: 65px;
  width: 100%;
  align-content: center;
}
.shape1 {
  width: 100%;
  height: 60%;
  background: linear-gradient(135deg, #e86c3d 80%, transparent 20%);
}

.shape3 {
  width: 11%;
  margin-left: 15%;
  background: linear-gradient(135deg, white 40%, transparent 52%, green 60%);
  clip-path: polygon(32% 5%, 100% 0%, 75% 100%, 0% 100%);
}
