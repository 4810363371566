@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.about-container{
    display: flex;
    margin: 2% 2%;
    font-size: .9rem;
}
.first-container{
    width: 48%;
    margin-left: 2%;
}

.heading{
    font-family: 'Roboto';
}

.second-container{
    width: 50%;
}

.second-container{
    border-radius: 15px;
    background-image: url('../../../assets/Images/background-image.jpg');
    /* width: 40%; */
    border-radius: 55px;
    height: 70%;
    /* margin-right:35px ; */
    padding: 56px;
}
.yearspan{
    font-size: 30px;
}

.sub-heading{
    color: #134871;
    font-size: 58px;
}
.para{
    color: #000000;
    size: 18px;
    font-weight: bold;
}
.button-about{
    background-color:#F88035;
    border: none;
    border-radius: 20px;
    padding: 10px  20px 10px 20px ;

    font-family: 'Roboto';
    font-size: 14px;
    font-weight: bold;
}
@media(max-width:1000px){
    .about-container{
        font-size: .7rem;
    }
}

@media(max-width:768px){
    .about-container{
        display: none;
    }
}