
.container-section-form{
    display: grid;
    grid-template-columns: 3fr 9fr;
}
.circle-design{
    width: 25vh;
    height: 25vh;
    border-radius: 50%;
    opacity: 0.3;
    background-color: #0070F0E5;
}
.section-whychoseus{
    display: grid;
    grid-template-columns: 7fr 5fr;
}
.content-section-container{
    padding-top: 2% ;
    padding-bottom: 2% ;
    font-size: 1rem;
}
.mobilesection{
    display: none;
}
@media(max-width:950px){
    .content-section-container{
        padding-top: .2% ;
        padding-bottom: .2% ;
        font-size: .5rem;
    }
}
@media(max-width:768px){
    .section-whychoseus{
        display: none;
    }
    .mobilesection{
        display: block;
        padding-left:5% ;
        padding-right: 5%;
        padding-bottom: 2vh;
        padding-top: 2vh;
        margin-top: 4vh;
        background-image:url('../../assets/Images/whychoseus-bg.png') ;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .card-container{
        display: grid;
        grid-template-columns: 4fr 4fr 4fr;
    }
    .card{
        margin-left: 4px;
        --bs-card-cap-padding-y: 0rem;
        --bs-card-cap-padding-x: 0rem;
    }
    .card-content-heading{
        font-size: .4rem;
        font-weight: bold;
        color: #1F284F;
    }
    .card-content-aboutus{
        font-size: .35rem;
        padding-bottom: 0px;
        color:gray;

    }
}