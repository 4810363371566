
@media only screen and (orientation: portrait){
    .Maindiv{
        transform: rotate(90deg);
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
        width: 100%;
    }
}