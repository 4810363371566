.mainbody{
  padding: 5%;
  display: flex;
  justify-content: center;
}
.Body {
  /* Body for the text */
  position: relative;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8); /* Slight white background for readability */
  color: #000; /* Ensures text is dark and readable */
  font-family: Arial, sans-serif;
}

.Body::before {
  /* Background image styling */
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../../assets/Images/lettericon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.4; /* Low opacity for background image */
  z-index: -1; /* Places image behind the text */
}

/* Ensures the text is readable */
.Body p {
  margin: 0;
  line-height: 1.6;
  color: #333; /* Darker color for text */
}

