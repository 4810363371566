
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');


.modal-body-leterhead {
    position: relative; /* add this */
    height: 100vh;
  }
  #selectionlettermodal{
    width: 60%;
  }
  .modal-body-leterhead::before {
    content: "";
    background-image: url("../../../assets/Images/logo.png");
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 0;
    /* margin-top: 5%; */
  }
.letterheadtitle{
    font-family: Nunito Sans;
    font-weight: 600;
}

.interview-letter-first-para{
    font-family:"Nunito Sans" ;
    font-weight: 400;
    font-size: 18px;
}

.footer{
   background-image: url('../../../assets/Images/Rectangle1.png');
   background-size: cover;
   height: 45px;
   position: absolute;
}
.bg-2{
    background-image: url('../../../assets/Images/Rectangle2.png');
    background-size: cover;
}



/* .main-image{
    background-image: url('../../../assets/Images/main-bg-image.png');
    background-repeat: no-repeat; 
     
} */
.footer-container{
  display: flex;
}

.footer-container-2{
  background-image: url('../../../assets/Images/Rectangle2.png');
  background-size: cover;
  height: 75px;
  width: 150px;
  display: flex;
  align-self: flex-end;
  
}

.background-image{
  position: absolute;
  margin-left: 105px;
}

.footerBg{
  background:linear-gradient(135deg, white 70%, #263672 30%) ;
  height: 75px;
  width: 100%;
  align-content: center;
}
.shape1 {
  width: 100%;
  background: linear-gradient(135deg, #e86c3d 80%, transparent 20%);
}

.shape3 {
  width: 10%;
  margin-left: 27%;
  background: linear-gradient(135deg, white 50%, green 50%);
  clip-path: polygon(30% 0%, 100% 0%, 70% 100%,   0%   100%);
}