.sidebar{
    position: fixed;
    top: 0;

    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-top: 20vh;
}
#aside-icons{
    position: fixed;
    top: 0;
    width: 5%;
    z-index: 3;
}
@media(max-width:768px){
    #aside-icons{
        display: none;
    }
  
}