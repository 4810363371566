.modal-body-leterhead {
    position: relative; 
    height: 100vh;
    
  }
  #confirmationlettermodal{
    width: 60%;
  }

  .modal-body-leterhead::before {
    /* content: ""; */
    /* background-image: url(""); */
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    z-index: 0;
    /* margin-top: 5%; */
  }
.letterheadtitle{
    font-family: Nunito Sans;
    /* letter-spacing: 3px; */
}