.contactHomepage {
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.contactus {
    display: none;
}

.bgcontactus {
    background: linear-gradient(90deg, #FFFFFF 75%, #037DA3 25%);
    height: 75vh;
    width: 75%;
    border-radius: 10px;
    box-shadow: 0px 50px 50px rgb(213, 210, 210);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0px;
}

.Gettotuch {
    width: 75%;
    height: 80%;
    display: flex;
}

.subGettouch {
    height: 90%;
    width: 50%;
    margin-left: 20px;
}

.contactHomepage{
    padding-top: 30px;
}

/* contactus */
@media only screen and (orientation: portrait) {
    .Gettotuch {
        display: flex;
        flex-flow: column-reverse;
        width: 150%;
    }

    .bgcontactus {
        background: linear-gradient(90deg, #FFFFFF 75%, #FFFFFF 25%);
        box-shadow: none;
        border-radius: none;
        border: none;
    }

    .contexts {
        display: none;
    }

    .contactHomepage {
        display: flex;
        padding: 1% 6%;
        height: auto;
    }

    .subGettouch {
        width: 100%;
        margin-left: 0px;
    }

    .contactus {
        display: block;
        text-align: center;
        padding: 1% 6%;
        margin-top: 10px;
        height: 110vh;
    }

    .contactIcon {
        font-size: 40px;
    }

    .bgcontactus {
        width: 95%;
    }
    .fw-medium{
        padding: 0px 10%;
    }

    .contactuscard {
        width: 100%;
        height: 20vh;
        border-radius: 20px;
        box-shadow: 0px 0px 10px gray;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        margin-bottom: 20px;
    }

}