.section-services-accordian{
    margin: 1% 5%;

}
.btn-services-count{
    border: 1px solid #0070F0;
    border-radius: 45%;
    padding: .4rem;
    color: #F88035;
}
.accordion-button span{
    margin-right: 20px;
}
.carousel-title{
    color: #414141;
    font-size: large;
}