.slider-container{
    margin: 1% 5%;

}
.main-container{
    display: flex;
    flex-direction: column;
}
#mainbanner{
    display: flex;
    justify-content: center;
}
.mblcarousel{
    display: none;
}
@media(max-width:768px){
    .image3{
        border-radius:0px;
    }
    .slider-container{
        display: none;
    }
    .mblcarousel{
        display: block;
        margin: 0% 3%;
    }
}