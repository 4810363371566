.mc-for-admit-card{
    border: 1px solid #ede8e8;
    border-radius: 15px;
}

.admitcard-container1{
    border-right: 1px solid rgb(234, 227, 227);
}

.logo-image-admit-card{
    width: 80%;
    padding-left: 0px;
}

.list-heading{
    font-family: 'Roboto';
    font-size: 1rem;
    color:#303C6C
}
