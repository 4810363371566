@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.main-container-aboutroute{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.para-about-sailors1, .para-about-sailors2{
    text-align:justify;
    font-size: 20px;
    color: #414141;
}
.workwithus-container{
    height: 60vh ;
    width: 90%;  
    background-image: url('../../../assets/Images/AboutMarine.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.apply-btn2{
    color: black;
    background-color: #ffffff;
    border: none;
    padding: 5px;
    width: 10%;
    border-radius: 10px;
}

.responsiveimg{
    display: none;
}
.testimonial{
    display: block;
}
.container-for-paras{
    padding: 70px;
}

@media only screen and (orientation: portrait) {
    .workwithus-container{
        height: 22vh;
    }
    .imgtext{
        display: none;
    }
    .container-for-paras{
        padding: 20px;
    }
    .para-about-sailors1, .para-about-sailors2{
        font-size: 40%;
        padding-right: 20px;
    }
    .responsiveimg{
        display: block;
        width:200px
    }
    .testimonial{
        display: none;
    }
}