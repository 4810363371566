.become-main {
    /* border: 1px solid black; */
    min-height: 10rem;
    display: grid;
    grid-template-columns: 35% 40%;
    justify-content: space-evenly;
    padding: 2rem 4rem;
    position: relative; /* Necessary for the pseudo-element to be positioned correctly */
    z-index: 1; /* Ensures the content inside this div is above the background */

    /* Background color for the entire div */
    background-color: rgba(255, 255, 255, 0.9); /* Adjust the opacity as needed */
    padding: 4rem;

}

.become-main::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/Images/become-bg.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1; /* Places the background image below the content */
    opacity: 1; /* Adjust this to control the visibility of the background image */
    pointer-events: none; /* Ensures that the image does not interfere with interactions */
}


.become-content h1{
    font-family: Poppins;
    font-size: 50px;
    font-weight: 700;
    line-height: 60px;
    text-align: left;
    color: #1F284F;

}
.become-form{
    /* border: 1px solid black; */
    box-shadow: 0px 4px 20px 0px #00000014;
     padding: 1rem;
     border-radius: 10px;
     opacity: 2;
     background-color: white;
}
.name{
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
}
.apply-div{
    display: flex;
    justify-content: space-between;
    margin: 10px;
    align-items: center;
}
.applybtn{
    padding:5px 20px;
}


@media screen and (min-width: 0px) and (max-width: 600px){
    .become-main {
        /* border: 1px solid black; */
        min-height: auto;
        display: grid;
        grid-template-columns: 100%;
        justify-content: space-evenly;
        padding: 2rem 2rem;
        background-color: rgba(255, 255, 255, 0.9); /* Adjust the opacity as needed */
    
    }
    .become-main::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/Images/become-bg.jpeg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1; /* Places the background image below the content */
        opacity: 1; /* Adjust this to control the visibility of the background image */
        pointer-events: none; /* Ensures that the image does not interfere with interactions */
    }
    
    
    .become-content h1{
        font-family: Poppins;
        font-size: 35px;
        font-weight: 700;
        line-height: 43px;
        text-align: left;
        color: #1F284F;
    
    }
    .become-form{
        /* border: 1px solid black; */
        box-shadow: 0px 4px 20px 0px #00000014;
         padding: 1rem;
         border-radius: 10px;
         opacity: 2;
         background-color: white;
    }
    .name{
        display: grid;
        grid-template-columns: 100%;
        justify-content: space-between;
    }
    .apply-div{
        display: flex;
        justify-content: space-between;
        margin: 10px;
        align-items: center;
    }
    .applybtn{
        padding:5px 20px;
    }
    
}