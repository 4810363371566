.divCut~span:hover {
    color: #F88035;
    cursor: pointer;
}

.headerw {
    /* font-family: Poppins; */
    display: grid;
    grid-template-columns: 2fr 7fr 3fr;
    /* margin: 2vh 10vh 2vh 10vh; */
    margin: 1% 5%;
    align-items: center;
    font-size: 1.3rem;
}

.headernav span {
    margin: 0rem 1rem;
}

.headernav span:hover {
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    background-color: white;
    border-radius: 0.5rem;
    padding: 0.5rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    right: 0;
}

.dropdown-item {
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f1f1f1;
}

.dropdown-divider {
    border-top: 1px solid #e0e0e0;
    margin: 0.5rem 0;
}

.link {
    text-decoration: none;
}

.loginbtn {
    font-size: 1em;
}

.headerr {
    display: none;
}

.image-containers {
    width: 200px;/* Adjust width as needed */
    height: 100px;/* Adjust height as needed */
    overflow: hidden;/* Hides any overflow */
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-containers img {
    width: 100%;/* Ensures image covers the container width */
    height:80%;/* Ensures image covers the container height */
    object-fit: cover;/* Zooms and crops the image to fill the container */
    object-position: center;/* Centers the image within the container */
}

@media (min-width: 769px) and (max-width:1020px) {
    .headerw {
        font-size: .9rem;
    }

    .headerr {
        display: none;
    }

}

@media (min-width: 0px) and (max-width:768px) {
    .headerr {
        margin: 2% 2%;
        /* height:5vh ; */
        display: grid;
        grid-template-columns: 6fr 6fr;
        align-items: center;
        font-size: 25px;
    }

    .headerw {
        display: none;
    }

    .btnapply {
        padding-left: 10%;
        padding-right: 10%;
    }

    .dropdown-item:hover {
        background-color: #F88035;
    }
}