.PersonDetails{
    display:grid;
    grid-template-columns: 6fr 6fr;
}
.responce{
    width: 80%;
}
@media only screen and (orientation: portrait){
    .responce{
        width: 100%;
    }
}